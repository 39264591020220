import {LitElement, html} from 'lit';
import {state, customElement} from 'lit/decorators.js';
// import { resolveRouterPath } from '../router';
import '../../components/navigation';
import '../../components/spinner';

import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';

import {styles as sharedStyles} from '../../styles/shared-styles';
import {styles} from './home-styles'

@customElement('app-home')
export class AppHome extends LitElement {

    // For more information on using properties and state in lit
    // check out this link https://lit.dev/docs/components/properties/
    @state() protected pageStructure: Object | null;
    @state() protected requesting: Boolean;
    @state() protected intervalId: number | undefined;

    static get styles() {
        return [
            sharedStyles,
            styles,
        ];
    }

    constructor() {
        super();
        this.pageStructure = null
        this.requesting = false
        this.intervalId = undefined
        this.retrievePageStructure(true)
    }

    retrievePageStructure(isInitial = false) {
        try {
            if (isInitial) {
                this.requesting = true
                this.pageStructure = null
            }
            fetch('https://api.tyny.dev/demo/documents?uri=/demo-app/home')
                .then(async (response) => {
                    if (response.status === 200) {
                        const jsonResponse = await response.json()
                        this.pageStructure = jsonResponse?.response
                    }
                    this.requesting = false
                })
        } catch (e) {
            console.error(`Couldn't retrieve page structure`, e)
            this.requesting = false
        }
    }

    async firstUpdated() {
        // this method is a lifecycle even in lit
        // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/
        // console.log('This is your home page');
    }

    connectedCallback() {
        super.connectedCallback()
        this.intervalId = setInterval(this.retrievePageStructure.bind(this), 3000)
    }

    disconnectedCallback() {
        super.disconnectedCallback()
        clearInterval(this.intervalId)
        this.intervalId = undefined
    }

    share() {
        if ((navigator as any).share) {
            (navigator as any).share({
                title: 'tyny.dev Demo App',
                text: 'Check out the Demo App for tyny.dev!',
                url: 'https://tyny.app',
            });
        }
    }

    render() {
        if (this.requesting) {
            return html`
                <app-navigation></app-navigation>
                <main>
                    <div class="center">
                        <app-spinner></app-spinner>
                    </div>
                </main>`
        } else if (this.pageStructure) {
            // @ts-ignore
            const heros = this.pageStructure.body?.hero || []
            // @ts-ignore
            const lanes = this.pageStructure.body?.lanes || []
            const popcornHeroImgUrl = '/assets/svg/hero.svg'
            return html`
                <app-navigation></app-navigation>
                ${heros.map((hero: { type: String, displayName: String; imageUrl: string }) => {
                    let imgSrc
                    switch (hero.type) {
                        case 'hero-unsplash':
                            imgSrc = popcornHeroImgUrl
                            const url = new URL(hero.imageUrl)
                            if (url.hostname === 'source.unsplash.com') {
                                imgSrc = hero.imageUrl
                            }
                            break;
                        default:
                            imgSrc = popcornHeroImgUrl
                    }
                    return html`
                        <header>
                            <img src=${imgSrc} alt=${hero.displayName} class="responsiveImg"/>
                        </header>`
                })}
                <main>
                    ${lanes.map((lane: { displayName: String; itemClass: String; itemIcon: String; }) => {
                        return html`
                            <div class="lane">
                                <div class="laneHeadline">${lane.displayName || ''}</div>
                                <div class="laneItems">
                                    ${Array.apply(null, Array(5)).map(() => {
                                        return html`
                                            <sl-card class="laneItem ${lane.itemClass || ''}">
                                                <sl-icon name="${lane.itemIcon || 'film'}" class="icon"></sl-icon>
                                            </sl-card>`
                                    })}
                                </div>
                            </div>`
                    })}
                </main>
            `;
        } else {
            return html`
                <app-navigation></app-navigation>
                <main>
                    <div class="center">
                        <sl-alert variant="danger" open>
                            <sl-icon slot="icon" name="exclamation-octagon"></sl-icon>
                            <strong>Page structure not available</strong><br/>
                            We couldn't talk to tyny.dev to retrieve the JSON document.
                        </sl-alert>
                    </div>
                </main>`
        }
    }
}
