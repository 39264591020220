import { css } from 'lit';

export const styles = css`
  header {
    padding: 0.5em;
    display: flex;
  }

  main {
    padding: 0.5em;
    min-height: 100vh;
  }

  .lane {
    margin: 0;
  }

  .laneHeadline {
    font-family: 'Felt Tip Roman', sans-serif;
    color: #fff;
    padding: 0 0.5em;
    font-size: 2em;
  }

  .laneItems {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .icon {
    font-size: 3em;
    color: #5583DD;
  }

  .responsiveImg {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  sl-card.laneItem::part(base) {
    text-align: center;
    border-radius: 8px;
    margin: 1em;
  }

  sl-card.green::part(base) {
    background-color: #48C040;
    border-color: #48C040;
  }

  sl-card.yellow::part(base) {
    background-color: #FFFFA9;
    border-color: #FFFFA9;
  }

  @media (min-width: 750px) {
    header {
      max-height: 30vh;
    }

    .lane {
      margin: 1em;
    }

    .laneHeadline {
      padding-left: 1em;
    }

    .laneItems {
      flex-direction: row;
      padding: 0.5em;
    }

    sl-card {
      width: 70vw;
    }
  }
`;