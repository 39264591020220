import React, { useState, useEffect } from 'react'
import { JsonForms } from '@jsonforms/react'
import {
  materialRenderers,
  materialCells,
} from '@jsonforms/material-renderers'
import { ThemeProvider, createTheme } from '@mui/material/styles'

const initialData = {}

const theme = createTheme({
  palette: {
    primary: {
      main: '#48C040'
    },
    error: {
      main: '#fff'
    },
  },
})

export default function Form () {
  const [requesting, setRequesting] = useState(false)
  const [pageStructure, setPageStructure] = useState(null)
  const [data, setData] = useState(initialData)
  const retrievePageStructure = (isInitial = false) => {
    try {
      if (isInitial) {
        setRequesting(true)
        setPageStructure(null)
      }
      fetch('https://api.tyny.dev/demo/documents?uri=/demo-app/form')
        .then(async (response) => {
          if (response.status === 200) {
            const jsonResponse = await response.json()
            setPageStructure(jsonResponse?.response)
          }
          setRequesting(false)
        })
    } catch (e) {
      console.error(`Couldn't retrieve form configuration`, e)
      setRequesting(false)
    }
  }

  useEffect(() => {
    retrievePageStructure(true)
    const interval = setInterval(retrievePageStructure, 3000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  if (requesting) {
    return (
      <main>
        <div className="center">
          <app-spinner></app-spinner>
        </div>
      </main>
    )
  } else if (pageStructure) {
    // @ts-ignore
    const schema = pageStructure.head || {}
    // @ts-ignore
    const body = pageStructure.body || {}
    const uischema = {
      type: 'VerticalLayout',
      ...body
    }
    return (
      <main id="formContainer">
        <ThemeProvider theme={theme}>
          <JsonForms
            schema={schema}
            uischema={uischema}
            data={data}
            renderers={materialRenderers}
            cells={materialCells}
            onChange={({ data, _errors }) => setData(data)}
          />
        </ThemeProvider>
      </main>
    )
  } else {
    return (
      <main>
        <div className="center">
          <sl-alert variant="danger" open>
            <sl-icon slot="icon" name="exclamation-octagon"></sl-icon>
            <strong>Form configuration not available</strong><br/>
            We couldn't talk to tyny.dev to retrieve the JSON document.
          </sl-alert>
        </div>
      </main>
    )
  }
}
