import {LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';

// import './pages/app-home';
// import './components/header';
import './styles/global.css';
import {router} from './router';

import {setBasePath} from '@shoelace-style/shoelace/dist/utilities/base-path.js';

setBasePath('/shoelace');

@customElement('app-index')
export class AppIndex extends LitElement {
    constructor() {
        super();
    }

    firstUpdated() {
        router.addEventListener('route-changed', () => {
            if ("startViewTransition" in document) {
                return (document as any).startViewTransition(() => {
                    this.requestUpdate();
                });
            } else {
                this.requestUpdate();
            }
        });
    }

    render() {
        // router config can be found in src/router.ts
        return router.render();
    }
}
