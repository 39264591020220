import {LitElement, css, html} from 'lit';
import {customElement} from 'lit/decorators.js';

import {ifDefined} from 'lit/directives/if-defined.js';

import '@shoelace-style/shoelace/dist/components/tab/tab.js';
import '@shoelace-style/shoelace/dist/components/tab-group/tab-group.js';

@customElement('app-navigation')
export class AppNavigation extends LitElement {
    static get styles() {
        return css`
          navigation {
            padding: 0.5em;
            background-color: #5583DD;
            display: flex;
            justify-content: center;
          }

          a {
            text-decoration: none;
            color: inherit;
          }

          sl-tab::part(base) {
            color: #fff;
          }

          sl-tab-group {
            --indicator-color: #48C040;
            --track-width: 4px;
          }
        `;
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <navigation>
                <sl-tab-group>
                    <sl-tab slot="nav" active=${ifDefined(location.pathname === '/' ? 'active' : undefined)}>
                        <a href="/">Page Structure Demo</a>
                    </sl-tab>
                    <sl-tab slot="nav" active=${ifDefined(location.pathname === '/terms' ? 'active' : undefined)}>
                        <a href="/terms">T&C Demo</a>
                    </sl-tab>
                    <sl-tab slot="nav" active=${ifDefined(location.pathname === '/form' ? 'active' : undefined)}>
                        <a href="/form">Form Demo</a>
                    </sl-tab>
                </sl-tab-group>
            </navigation>
        `;
    }
}
