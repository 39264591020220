// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md

import {html} from 'lit';

if (!(globalThis as any).URLPattern) {
    await import("urlpattern-polyfill");
}

import {Router} from '@thepassle/app-tools/router.js';
import {lazy} from '@thepassle/app-tools/router/plugins/lazy.js';
// @ts-ignore
import React from 'react';
// @ts-ignore
import {createRoot} from 'react-dom/client';
// @ts-ignore
import Form from './components/Form'

// @ts-ignore
import {title} from '@thepassle/app-tools/router/plugins/title.js';

import './pages/app-home/app-home';

const baseURL: string = (import.meta as any).env.BASE_URL;
const reactContainer = document.createElement('div')
let reactRoot: any

reactContainer.setAttribute('id', 'reactContainer')

export const router = new Router({
    routes: [
        {
            path: resolveRouterPath(),
            title: 'Page Structure Demo',
            render: () => html`
                <app-home></app-home>`
        },
        {
            path: resolveRouterPath('terms'),
            title: 'Terms & Conditions Demo',
            plugins: [
                lazy(() => import('./pages/app-terms/app-terms.js')),
            ],
            render: () => html`
                <app-terms></app-terms>`
        },
        {
            path: resolveRouterPath('form'),
            title: 'Form Demo',
            render: () => {
                return html`
                    <app-navigation></app-navigation>`
            }
        },
        // {
        //   path: resolveRouterPath('about'),
        //   title: 'About',
        //   plugins: [
        //     lazy(() => import('./pages/app-about/app-about.js')),
        //   ],
        //   render: () => html`<app-about></app-about>`
        // }
    ]
});

// @ts-ignore
router.addEventListener('route-changed', ({context}) => {
    const {url} = context
    document.querySelector('body')?.appendChild(reactContainer)
    if (!reactRoot) {
        reactRoot = createRoot(document.getElementById('reactContainer'));
    }
    switch (url.pathname) {
        case '/form':
            reactRoot.render(<Form/>);
            break
        default:
            // @ts-ignore
            reactRoot.render(<span/>)
        // if (document.querySelector('body > #reactContainer')) {
        //     document.querySelector('body').removeChild(reactContainer)
        // }
    }
    //const route = router.render();
    //document.querySelector('main').innerHTML = route;
});

// This function will resolve a path with whatever Base URL was passed to the vite build process.
// Use of this function throughout the starter is not required, but highly recommended, especially if you plan to use GitHub Pages to deploy.
// If no arg is passed to this function, it will return the base URL.

export function resolveRouterPath(unresolvedPath?: string) {
    let resolvedPath = baseURL;
    if (unresolvedPath) {
        resolvedPath = resolvedPath + unresolvedPath;
    }

    return resolvedPath;
}
