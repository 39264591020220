import {LitElement, css, html} from 'lit';
import {customElement} from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';

@customElement('app-spinner')
export class AppSpinner extends LitElement {
    static get styles() {
        return css`
          sl-spinner {
            font-size: 3rem;
            --track-color: var(--sl-color-neutral-200);
            --indicator-color: #48C040;
            --track-width: 4px;
          }
        `;
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <sl-spinner></sl-spinner>
        `;
    }
}
