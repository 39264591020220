import { css } from 'lit';

// these styles can be imported from any component
// for an example of how to use this, check /pages/about-about.ts
export const styles = css`
  @media (min-width: 1000px) {
    sl-card {
      max-width: 70vw;
    }
  }

  //main {
  //  margin-top: 80px;
  //}

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
`;